import * as React from "react"

function trueBlueLogo() {

	return (

    <div className="logo-svg">
        
        <svg width="49" height="35" viewBox="0 0 49 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 10.1178V0.445312H2.82056V3.83568H5.65537V6.65624H2.82056V10.1178C2.82056 12.0409 4.35905 13.5937 6.28216 13.5937V16.4142C5.00008 16.4142 3.80348 16.0296 2.82056 15.3601C1.12537 14.2347 0 12.3116 0 10.1178Z" fill="black"/>
            <path d="M13.4048 6.65624C11.4959 6.65624 9.94319 8.20898 9.94319 10.1178V15.3601V16.4142H7.12263V10.1178V3.83568H9.94319V4.87559C10.9261 4.22031 12.1227 3.83568 13.4048 3.83568V6.65624Z" fill="black"/>
            <path d="M14.1201 3.83568H16.9406V6.65624V7.76737V10.7019C16.9406 12.2831 18.2512 13.5937 19.8324 13.5937C21.3994 13.5937 22.7099 12.2831 22.7099 10.7019V7.76737V6.65624V3.83568H25.5305V10.7019C25.5305 13.8359 22.9664 16.4142 19.8324 16.4142C16.6984 16.4142 14.1201 13.8359 14.1201 10.7019V3.83568Z" fill="black"/>
            <path d="M29.7379 8.25171C30.3646 7.28303 31.433 6.65624 32.6581 6.65624C33.869 6.65624 34.9374 7.28303 35.5642 8.22322C35.5642 8.23747 35.5784 8.23747 35.5784 8.25171H35.5357H29.7379ZM32.6581 16.4142C34.8519 16.4142 36.775 15.2746 37.9146 13.5937L35.5642 12.0125C34.9374 12.9669 33.869 13.5937 32.6581 13.5937C31.0769 13.5937 29.7236 12.5253 29.3247 11.058H31.3048H34.1254H35.9915H38.8691C38.9118 10.7589 38.9403 10.4312 38.9403 10.1178C38.9403 9.4768 38.8406 8.83577 38.6554 8.25171C38.4844 7.6819 38.228 7.14058 37.9146 6.65624C36.775 4.96106 34.8519 3.83568 32.6581 3.83568C31.3761 3.83568 30.1795 4.22031 29.1965 4.87559C27.9999 5.67332 27.1025 6.85568 26.6466 8.25171C26.4757 8.83577 26.376 9.4768 26.376 10.1178C26.376 10.4312 26.4045 10.7589 26.433 11.058C26.7179 12.8672 27.7435 14.4057 29.1965 15.3601C30.1795 16.0296 31.3761 16.4142 32.6581 16.4142Z" fill="black"/>
            <path d="M9.94361 33.945C10.955 34.6146 12.1374 34.9992 13.4194 34.9992C16.881 34.9992 19.7016 32.1786 19.7016 28.7028C19.7016 25.2412 16.881 22.4206 13.4194 22.4206C12.1374 22.4206 10.955 22.8052 9.94361 23.4605V13.3535H7.12305V28.7028V34.9992H9.94361V33.945ZM13.4194 32.1786C11.4963 32.1786 9.94361 30.6259 9.94361 28.7028C9.94361 26.7939 11.4963 25.2412 13.4194 25.2412C15.3283 25.2412 16.881 26.7939 16.881 28.7028C16.881 30.6259 15.3283 32.1786 13.4194 32.1786Z" fill="black"/>
            <path d="M20.4084 17.8052H23.229V34.9992H20.4084V17.8052Z" fill="black"/>
            <path d="M23.9419 22.4206H26.7625V25.2412V26.3523V29.2868C26.7625 30.8681 28.073 32.1786 29.6543 32.1786C31.2212 32.1786 32.5318 30.8681 32.5318 29.2868V26.3523V25.2412V22.4206H35.3524V29.2868C35.3524 32.4208 32.7882 34.9992 29.6543 34.9992C26.5203 34.9992 23.9419 32.4208 23.9419 29.2868V22.4206Z" fill="black"/>
            <path d="M39.5597 26.8366C40.1865 25.868 41.2549 25.2412 42.48 25.2412C43.6908 25.2412 44.7592 25.868 45.386 26.8082C45.386 26.8224 45.4003 26.8224 45.4003 26.8366H45.3575H39.5597ZM42.48 34.9992C44.6738 34.9992 46.5969 33.8596 47.7365 32.1786L45.386 30.5974C44.7592 31.5518 43.6908 32.1786 42.48 32.1786C40.8988 32.1786 39.5455 31.1102 39.1466 29.643H41.1267H43.9472H45.8134H48.6909C48.7336 29.3438 48.7621 29.0162 48.7621 28.7028C48.7621 28.0617 48.6624 27.4207 48.4772 26.8366C48.3063 26.2668 48.0499 25.7255 47.7365 25.2412C46.5969 23.546 44.6738 22.4206 42.48 22.4206C41.1979 22.4206 40.0013 22.8052 39.0184 23.4605C37.8218 24.2583 36.9243 25.4406 36.4685 26.8366C36.2975 27.4207 36.1978 28.0617 36.1978 28.7028C36.1978 29.0162 36.2263 29.3438 36.2548 29.643C36.5397 31.4521 37.5654 32.9906 39.0184 33.945C40.0013 34.6146 41.1979 34.9992 42.48 34.9992Z" fill="black"/>
            <path d="M25.375 2.67099L14.2459 2.67098L14.2459 -4.86469e-07L17.4832 -3.44962e-07L25.375 0L25.375 2.67099Z" fill="black"/>
        </svg>

    </div>

    );
}

export default trueBlueLogo;
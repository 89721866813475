import * as React from "react"

function royalPalmLogo() {

	return (

        <div className="logo-svg">

            <svg width="101" height="35" viewBox="0 0 101 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.60335 5.24978V25.6666H3.43667V5.24978H4.60335Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7783 5.24978V25.6666H34.9449V5.24978H33.7783Z" fill="black"/>
            <path d="M7.52846 9.30052C9.27848 8.1343 13.3618 7.47863 16.2785 9.30048C18.8684 10.9182 19.1586 13.3694 19.1911 15.5312C19.2236 13.3694 19.5138 10.9182 22.1037 9.30048C25.0204 7.47863 29.1038 8.1343 30.8538 9.30052L21.7614 14.7817C23.1923 14.1212 24.8675 13.8033 26.6637 14.7588C29.6998 16.3738 30.9879 19.4838 30.8529 21.5825L20.7031 17.0157C21.5635 20.6807 23.3904 23.3336 26.77 23.3336H19.1957H19.1866H11.6123C14.9919 23.3336 16.8188 20.6807 17.6792 17.0157L7.52937 21.5825C7.3944 19.4838 8.68242 16.3738 11.7185 14.7588C13.5147 13.8033 15.1899 14.1212 16.6209 14.7817L7.52846 9.30052Z" fill="black"/>
            <path d="M6.36514 21.6579C6.20135 19.111 7.72184 15.5638 11.1707 13.7293C11.4491 13.5812 11.7253 13.4582 11.9985 13.358L5.3506 9.35045L4.01986 8.54247V23.3342H6.39792L6.36514 21.6579Z" fill="black"/>
            <path d="M32.0159 21.6579C32.1797 19.111 30.6592 15.5638 27.2104 13.7293C26.9319 13.5812 26.6558 13.4582 26.3826 13.358L33.0305 9.35045L34.3612 8.54247V23.3342H31.9832L32.0159 21.6579Z" fill="black"/>
            <path d="M4.01986 23.3342V25.6664C6.41192 25.6664 8.4677 27.1062 9.36786 29.1664H29.0046C29.9048 27.1062 31.9605 25.6664 34.3526 25.6664V23.3331L19.1866 23.3336L4.01986 23.3342Z" fill="black"/>
            <path d="M3.55016 2.33276L7.10032 5.88292L3.55016 9.43308L0 5.88292L3.55016 2.33276Z" fill="black"/>
            <path d="M34.8547 2.33276L31.3045 5.88292L34.8547 9.43308L38.4049 5.88292L34.8547 2.33276Z" fill="black"/>
            <path d="M19.1863 32.6666H6.35289V33.8333H8.68624V35H16.853L19.1863 32.6666Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.68624 35V33.8333H6.35289V32.6666H19.1863L16.853 35H8.68624Z" fill="black"/>
            <path d="M19.1948 32.6666H32.0282V33.8333H29.6949V35H21.5281L19.1948 32.6666Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M29.6949 35V33.8333H32.0282V32.6666H19.1948L21.5281 35H29.6949Z" fill="black"/>
            <path d="M19.2359 0L22.7861 3.55016L19.2359 7.10032L15.6858 3.55016L19.2359 0Z" fill="black"/>
            <path d="M6.35289 30.3339H32.0194V32.6672L19.1863 32.6666H6.35289V30.3339Z" fill="black"/>
            <path d="M44.7006 17.98H49.5161V11.7217C51.1419 12.5246 48.5078 18.0006 53.6114 18.0006C54.414 18.0006 55.0932 17.8153 55.6488 17.4241C56.7189 16.683 57.0071 15.6949 57.0071 14.892C57.0071 14.6861 56.9659 14.3568 56.8836 13.9039C56.8013 13.4715 56.5131 12.9569 55.9987 12.401C55.7517 13.2863 54.9697 13.8627 54.0025 13.8627C53.7761 13.8627 53.5703 13.8421 53.3851 13.7803C52.3767 13.5127 52.8912 11.7011 51.2037 11.1453C51.3477 11.1659 51.4918 11.1864 51.6358 11.1864C52.3149 11.1864 53.1793 10.9394 53.9407 10.26C54.6816 9.60128 55.2166 8.59255 55.2166 7.37795V7.35736C55.2166 5.21637 53.9819 3.56945 51.3683 3.56945H44.7006V17.98ZM50.7097 7.48088C50.648 10.1365 50.257 10.4865 49.5161 10.4865V4.20763C49.5367 4.20763 49.5367 4.20763 49.5573 4.20763C50.3187 4.20763 50.7097 5.23696 50.7097 7.27501C50.7097 7.33677 50.7097 7.41912 50.7097 7.48088Z" fill="black"/>
            <path d="M57.0511 10.96C57.0717 12.7716 57.8125 14.6244 58.965 16.0037C60.1174 17.4035 61.6609 18.2682 63.2867 18.2682C63.3484 18.2682 63.3896 18.2682 63.4513 18.2682C67.2379 18.1652 69.4811 14.0685 69.4811 10.7953C69.4811 10.7541 69.4811 10.713 69.4811 10.6718C69.4193 8.83958 68.8431 6.96622 67.773 5.56634C66.7028 4.16646 65.1182 3.28124 63.2043 3.28124C63.1632 3.28124 63.1426 3.28124 63.1015 3.28124C58.8827 3.38418 57.0511 7.08974 57.0511 10.6512C57.0511 10.7541 57.0511 10.8571 57.0511 10.96ZM60.3232 5.0105C60.3438 4.70171 60.4261 4.49584 60.6113 4.43408C60.6525 4.4135 60.6937 4.4135 60.7554 4.4135C61.1876 4.4135 61.9696 5.3193 62.7722 6.82211C63.5336 8.22199 64.1922 9.80715 64.4391 10.3424C64.5832 10.6718 64.8713 11.4952 65.2006 12.4216C65.6739 13.8833 66.0855 15.5096 66.0855 16.2507C66.0855 16.3948 66.1266 16.8889 65.7768 17.033C65.7356 17.0536 65.6945 17.0536 65.6327 17.0536C65.3446 17.0536 64.9536 16.7036 64.4597 15.9625C63.7188 14.892 62.9368 13.1833 62.4841 12.0922C62.3194 11.7217 62.196 11.4541 62.1342 11.2688C61.9902 10.9188 61.6403 10.0954 61.311 9.1278C60.776 7.64557 60.3232 5.97807 60.3232 5.15461C60.3232 5.09285 60.3232 5.05168 60.3232 5.0105Z" fill="black"/>
            <path d="M68.3002 3.56945C68.8558 4.78405 69.8848 6.90446 70.7697 8.77782C71.2842 9.86891 71.8192 11.0012 72.3749 12.1952V17.98H77.1905V12.1952L77.3139 11.9481C77.3963 11.7834 77.4992 11.557 77.6432 11.2482C78.5281 9.39542 80.4832 5.21637 81.3064 3.56945H76.4085C76.5937 3.85766 76.7789 4.24881 76.9847 4.72229C77.3963 5.71045 77.4992 6.78094 77.4992 7.52205C77.4992 7.91319 77.458 8.44844 77.3963 9.10721C77.3139 9.76597 77.0464 10.6306 76.5937 11.7011C75.9557 10.363 75.3795 9.1278 74.8444 7.99554C73.9183 6.081 72.9511 4.06353 72.7247 3.56945H68.3002Z" fill="black"/>
            <path d="M78.6942 15.6743C78.8794 16.2507 79.3322 16.786 80.0319 17.2594C80.711 17.7329 81.4313 17.98 82.1516 17.98C83.0571 17.98 83.8597 17.5888 84.4359 16.9301C85.0121 16.2713 85.259 15.4272 85.259 14.6038C85.259 14.5009 85.2385 14.3773 85.2385 14.2538C84.9298 14.3773 84.5799 14.4391 84.2095 14.4391C83.6538 14.4391 83.0571 14.2744 82.5426 13.8627C81.2255 12.8128 81.5753 11.5776 82.2339 9.74539C82.666 11.4747 83.695 11.7011 85.0944 11.7629L86.5555 17.98H91.2065C91.2065 17.877 90.2393 14.2744 89.3543 10.6924C88.7987 8.59255 88.2019 6.22511 87.5228 3.56945H83.304C82.7278 5.68986 81.7194 7.56322 80.7727 9.2719C80.2994 10.1159 79.8467 10.9188 79.4557 11.6805C78.6531 13.1833 78.5296 14.1097 78.5296 14.6655C78.5296 15.0155 78.5913 15.3449 78.6942 15.6743ZM82.6043 8.77782C82.9953 7.72792 83.3452 6.5339 83.5304 5.13402L84.8063 10.61C83.5921 10.5483 83.0365 10.26 82.6043 8.77782Z" fill="black"/>
            <path d="M100.483 17.98V11.7629C99.7417 13.7392 98.4452 14.9332 97.1281 14.9332H97.0458V3.56945H92.2302V17.98H100.483Z" fill="black"/>
            <path d="M44.7006 33.9066H49.5161V27.7513C49.8248 26.9484 50.5657 26.8661 51.5329 26.8661C52.4384 26.8661 52.9735 26.8867 53.0146 26.8867C54.3729 26.8867 55.7929 26.3926 55.9575 23.881C55.9575 23.7163 55.9781 23.5722 55.9781 23.4075C55.9781 22.09 55.5665 21.0401 54.5993 20.3813C53.632 19.7226 52.212 19.4961 50.5657 19.4961H44.7006V33.9066ZM49.5161 26.7014V21.9871C49.7219 21.9459 49.866 21.9253 49.9483 21.9253C50.7509 21.9253 51.1419 22.79 51.1419 24.1075C51.1419 24.1692 51.1419 24.2104 51.1419 24.2516C51.1419 26.2897 49.8454 25.5691 49.5161 26.7014Z" fill="black"/>
            <path d="M54.6983 31.601C54.8835 32.1774 55.3363 32.7126 56.036 33.1861C56.7151 33.6596 57.4354 33.9066 58.1556 33.9066C59.0611 33.9066 59.8637 33.5155 60.44 32.8567C61.0162 32.198 61.2631 31.3539 61.2631 30.5305C61.2631 30.4275 61.2426 30.304 61.2426 30.1805C60.9339 30.304 60.584 30.3658 60.2136 30.3658C59.6579 30.3658 59.0611 30.2011 58.5467 29.7893C57.2296 28.7394 57.5794 27.5043 58.238 25.6721C58.6701 27.4013 59.6991 27.6278 61.0985 27.6895L62.5596 33.9066H67.2106C67.2106 33.8037 66.2434 30.2011 65.3584 26.619C64.8028 24.5192 64.206 22.1518 63.5269 19.4961H59.3081C58.7319 21.6165 57.7235 23.4899 56.7768 25.1986C56.3035 26.0426 55.8507 26.8455 55.4597 27.6072C54.6571 29.11 54.5337 30.0364 54.5337 30.5922C54.5337 30.9422 54.5954 31.2716 54.6983 31.601ZM58.6084 24.7045C58.9994 23.6546 59.3492 22.4606 59.5345 21.0607L60.8104 26.5367C59.5962 26.4749 59.0406 26.1867 58.6084 24.7045Z" fill="black"/>
            <path d="M76.4866 33.9066V27.6895C75.7457 29.6658 74.4492 30.8598 73.1321 30.8598H73.0498V19.4961H68.2342V33.9066H76.4866Z" fill="black"/>
            <path d="M77.5191 33.9066H82.0466C80.1327 31.9921 78.3011 29.3364 78.2394 21.3077C79.3507 24.2928 81.4498 29.8923 82.3758 32.4038C82.417 32.4038 83.8987 29.5011 85.5039 26.7014C86.4094 25.0339 87.4384 23.1605 88.6114 21.0607V33.9066H93.427V19.4961H88.6114L84.8042 26.3102C83.6723 23.3664 82.4376 20.3813 82.1083 19.4961H77.5191V33.9066Z" fill="black"/>
            </svg>

        </div>

    );
}

export default royalPalmLogo;
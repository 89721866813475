import * as React from "react"
import { Group } from '@mantine/core'
import { useTimer } from 'use-timer';

import FullLogo from "../../../components/logo"
import AzulManagementLogo from "../../../images/logos/azulManagementLogo"
import BlueMarkLogo from "../../../images/logos/blueMarkLogo"
import TrueBlueLogo from "../../../images/logos/trueBlueLogo"
import RoyalPalmLogo from "../../../images/logos/royalPalmLogo"



function ComingSoon() {

    const { time } = useTimer({
        initialTime: 10,
        autostart: true,
        timerType: 'DECREMENTAL',
    });
    
	return (

        <div className="coming-soon">
            
            <div id="max-width">
                <div className="logo">
                    <FullLogo />
                </div>

                <div className="text">
                    <h1>Redirecting...</h1>
                    <p>Welcome. <strong>Our website is currently under construction.</strong> You will be redirected to our Azul&nbsp;Management site in {time} seconds. <a href="https://azulmanagement.co" rel="noreferrer"><br />Click&nbsp;here</a> to go now.</p>
                </div>
                
            </div>

            <div id="max-width">
                <footer>
                    
                    <div className="all-company-logos">
                        <Group spacing="xl">
                            <BlueMarkLogo />
                            <AzulManagementLogo />
                            <TrueBlueLogo />
                            <RoyalPalmLogo />
                        </Group>
                    </div>

                    <div className="footer-wrapper">
                        <p>Copyright © {new Date().getFullYear()} Blue Mark Holdings Ltd. All Rights Reserved. Belize.</p>
                    </div>
                </footer>
            </div>

        </div>
        
    );
}

export default ComingSoon;

import * as React from "react"

function blueMarkLogo() {

	return (

    <div className="logo-svg">
        
        <svg width="132" height="36" viewBox="0 0 132 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.917969V27.6709H8.98773V0.917969H0ZM19.1348 14.0269C21.1733 12.7896 22.5434 10.549 22.5434 8.00749C22.5434 4.09487 19.3687 0.917969 15.4588 0.917969H12.4846V27.6709H16.4279C20.3378 27.6709 23.5125 24.494 23.5125 20.5813C23.5125 17.6385 21.708 15.097 19.1348 14.0269Z" fill="black"/>
            <path d="M40.3778 0.917969H30.5864L38.5733 27.6709H48.3982L40.3778 0.917969ZM54.4031 0.917969H44.6116L52.5985 27.6709H62.4234L54.4031 0.917969Z" fill="black"/>
            <path d="M24.7852 27.6713H34.8106L29.4637 10.1816L24.7852 27.6713Z" fill="black"/>
            <path d="M0 30.6445H63.2727L64.9713 35.7403H0V30.6445Z" fill="black"/>
            <path d="M74.8477 13.1368V0.791016H83.1103C84.7125 0.791016 85.9503 1.09912 86.8237 1.71534C87.6971 2.3262 88.1338 3.19694 88.1338 4.32756C88.1338 5.45819 87.6623 6.26999 86.7192 6.76296C87.9945 7.25593 88.6322 8.20169 88.6322 9.60024C88.6322 11.9579 86.9577 13.1368 83.6086 13.1368H74.8477ZM83.6086 8.59553H78.8986V9.55201H83.6086C84.0909 9.55201 84.332 9.3859 84.332 9.05368C84.332 8.74825 84.0909 8.59553 83.6086 8.59553ZM83.1103 4.37579H78.8986V5.33226H83.1103C83.5979 5.33226 83.8417 5.17955 83.8417 4.87412C83.8417 4.5419 83.5979 4.37579 83.1103 4.37579Z" fill="black"/>
            <path d="M94.7729 9.79314H96.1714V13.1368H94.7729C93.0689 13.1368 91.8017 12.8072 90.9711 12.1482C90.1459 11.4837 89.7333 10.4201 89.7333 8.95723V0.791016H93.5029V8.95723C93.5029 9.5145 93.9263 9.79314 94.7729 9.79314Z" fill="black"/>
            <path d="M102.433 13.2172H102.023C100.319 13.2172 99.0516 12.8876 98.221 12.2285C97.3958 11.5641 96.9832 10.5004 96.9832 9.0376V2.43873H100.753V9.0376C100.753 9.59488 101.176 9.87351 102.023 9.87351H105.037C105.728 9.87351 106.074 9.59488 106.074 9.0376V2.43873H109.843V13.1368H106.074V12.0196C105.372 12.818 104.158 13.2172 102.433 13.2172Z" fill="black"/>
            <path d="M116.097 9.79314H123.523V13.1368H116.097C114.393 13.1368 113.125 12.8072 112.295 12.1482C111.47 11.4837 111.057 10.4201 111.057 8.95723V6.61828C111.057 5.15544 111.47 4.09447 112.295 3.43539C113.125 2.77095 114.393 2.43873 116.097 2.43873H119.818C121.206 2.43873 122.235 2.70397 122.905 3.23445C123.58 3.76493 123.917 4.62496 123.917 5.81452C123.917 7.00409 123.58 7.86679 122.905 8.40263C122.235 8.93847 121.206 9.20639 119.818 9.20639H118.186C116.675 9.20639 115.555 8.96258 114.827 8.47497V8.95723C114.827 9.5145 115.25 9.79314 116.097 9.79314ZM119.818 5.78237H115.277C114.977 5.78237 114.827 5.87882 114.827 6.07172C114.827 6.26999 114.977 6.36912 115.277 6.36912H119.818C120.113 6.36912 120.26 6.26999 120.26 6.07172C120.26 5.87882 120.113 5.78237 119.818 5.78237Z" fill="black"/>
            <path d="M78.8986 16.6743L82.8531 21.32L86.7996 16.6743H90.8505V29.0201H86.7996V22.3569L82.8531 26.9946L78.8986 22.3569V29.0201H74.8477V16.6743H78.8986Z" fill="black"/>
            <path d="M98.2612 29.0201H97.2083C95.5043 29.0201 94.237 28.6905 93.4065 28.0314C92.5813 27.367 92.1687 26.3033 92.1687 24.8405V22.5016C92.1687 21.0387 92.5813 19.9777 93.4065 19.3187C94.237 18.6542 95.5043 18.322 97.2083 18.322H98.2612C99.7508 18.322 100.839 18.673 101.524 19.3749V18.322H105.294V29.0201H101.524V27.9671C100.839 28.6691 99.7508 29.0201 98.2612 29.0201ZM97.2083 25.6764H100.488C101.179 25.6764 101.524 25.3978 101.524 24.8405V22.5016C101.524 21.9443 101.179 21.6656 100.488 21.6656H97.2083C96.3617 21.6656 95.9383 21.9443 95.9383 22.5016V24.8405C95.9383 25.3978 96.3617 25.6764 97.2083 25.6764Z" fill="black"/>
            <path d="M110.511 22.5016V29.0201H106.741V18.322H110.511V19.5518C111.154 18.7319 112.279 18.322 113.886 18.322H115.188V21.6656H111.547C110.856 21.6656 110.511 21.9443 110.511 22.5016Z" fill="black"/>
            <path d="M116.081 16.6743H119.85V23.3937L124.166 18.322H128.676L124.247 23.5063L128.925 29.0201H124.408L119.85 23.6509V29.0201H116.081V16.6743Z" fill="black"/>
            <path d="M77.8396 32.0449H79.0664V35.7914H77.8396V34.4621H76.1395V35.7914H74.9102V32.0449H76.1395V33.3743H77.8396V32.0449Z" fill="black"/>
            <path d="M84.4916 32.0449H85.5599C86.1567 32.0449 86.5998 32.1604 86.8892 32.3913C87.1787 32.6222 87.3234 32.9946 87.3234 33.5084V34.328C87.3234 34.8418 87.1787 35.2142 86.8892 35.4451C86.5998 35.676 86.1567 35.7914 85.5599 35.7914H84.4916C83.8948 35.7914 83.4517 35.676 83.1622 35.4451C82.8728 35.2142 82.7281 34.8418 82.7281 34.328V33.5084C82.7281 32.9946 82.8728 32.6222 83.1622 32.3913C83.4517 32.1604 83.8948 32.0449 84.4916 32.0449ZM84.4916 34.7036H85.5599C85.916 34.7036 86.0941 34.5784 86.0941 34.328V33.5084C86.0941 33.258 85.916 33.1328 85.5599 33.1328H84.4916C84.1338 33.1328 83.955 33.258 83.955 33.5084V34.328C83.955 34.5784 84.1338 34.7036 84.4916 34.7036Z" fill="black"/>
            <path d="M90.985 32.0449H92.2144V34.7036H94.7364V35.7914H90.985V32.0449Z" fill="black"/>
            <path d="M100.945 35.7914H98.2712V32.0449H100.945C101.543 32.0449 101.986 32.1604 102.274 32.3913C102.563 32.6222 102.708 32.9946 102.708 33.5084V34.328C102.708 34.8418 102.563 35.2142 102.274 35.4451C101.986 35.676 101.543 35.7914 100.945 35.7914ZM100.945 33.1328H99.5006V34.7036H100.945C101.301 34.7036 101.479 34.5784 101.479 34.328V33.5084C101.479 33.258 101.301 33.1328 100.945 33.1328Z" fill="black"/>
            <path d="M106.367 32.0449H107.597V35.7914H106.367V32.0449Z" fill="black"/>
            <path d="M111.341 32.0449H112.57L114.29 34.067V32.0449H115.519V35.7914H114.29L112.57 33.7694V35.7914H111.341V32.0449Z" fill="black"/>
            <path d="M123.549 32.0449V33.1328H120.942C120.584 33.1328 120.405 33.258 120.405 33.5084V34.328C120.405 34.5784 120.584 34.7036 120.942 34.7036H122.32V33.9182H123.549V35.7914H120.942C120.345 35.7914 119.902 35.676 119.613 35.4451C119.323 35.2142 119.179 34.8418 119.179 34.328V33.5084C119.179 32.9946 119.323 32.6222 119.613 32.3913C119.902 32.1604 120.345 32.0449 120.942 32.0449H123.549Z" fill="black"/>
            <path d="M128.565 32.0449H131.053V33.1328H128.565C128.425 33.1328 128.355 33.1832 128.355 33.284C128.355 33.3767 128.425 33.423 128.565 33.423H129.738C130.224 33.423 130.584 33.5173 130.819 33.706C131.054 33.893 131.172 34.1938 131.172 34.6085C131.172 35.0231 131.054 35.3239 130.819 35.5109C130.584 35.6979 130.224 35.7914 129.738 35.7914H127.253V34.7036H129.738C129.88 34.7036 129.95 34.6532 129.95 34.5524C129.95 34.4597 129.88 34.4133 129.738 34.4133H128.565C128.079 34.4133 127.718 34.3198 127.482 34.1328C127.248 33.9442 127.131 33.6426 127.131 33.2279C127.131 32.8132 127.248 32.5124 127.482 32.3254C127.718 32.1384 128.079 32.0449 128.565 32.0449Z" fill="black"/>
        </svg>

    </div>

    );
}

export default blueMarkLogo;